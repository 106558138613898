@import url(https://fonts.googleapis.com/css?family=Roboto+Mono:300,700|Roboto:100,400,700&display=swap);
html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

html {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  html.mobile {
    font-size: 18px; }

a {
  text-decoration: none;
  color: inherit; }

* {
  outline: none;
  flex-shrink: 0; }

.AttributionWindow .row-container {
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 1rem 2rem; }
  .AttributionWindow .row-container .row {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    border-bottom: solid 1px #CCC; }
    .AttributionWindow .row-container .row:last-child {
      border-bottom: none; }
    .AttributionWindow .row-container .row .transparent {
      background: url(/static/media/transparent.1c68cb14.png) repeat top left;
      background-size: 8px 8px;
      margin-right: 1rem; }
      .AttributionWindow .row-container .row .transparent .icon {
        height: 4rem;
        width: 4rem;
        -webkit-filter: drop-shadow(0 0 0.125rem rgba(0, 0, 0, 0.5));
                filter: drop-shadow(0 0 0.125rem rgba(0, 0, 0, 0.5));
        -webkit-filter: none;
                filter: none; }
        .AttributionWindow .row-container .row .transparent .icon.black {
          -webkit-filter: brightness(0%);
                  filter: brightness(0%); }
    .AttributionWindow .row-container .row .info {
      flex: 1 1;
      display: flex;
      flex-direction: column;
      margin-right: 1rem; }
      .AttributionWindow .row-container .row .info .path {
        color: blue;
        text-decoration: underline;
        word-break: break-all; }
      .AttributionWindow .row-container .row .info .artist {
        flex: 1 1; }
        .AttributionWindow .row-container .row .info .artist:before {
          content: 'by\00a0'; }
    .AttributionWindow .row-container .row .link {
      color: blue;
      text-decoration: underline;
      margin-right: 1rem; }

.mobile .AttributionWindow .row-container .row {
  flex-direction: column; }
  .mobile .AttributionWindow .row-container .row .transparent {
    margin-right: 0; }
  .mobile .AttributionWindow .row-container .row .info {
    margin-right: 0;
    margin-bottom: 1rem;
    text-align: center; }
  .mobile .AttributionWindow .row-container .row .link {
    margin-right: 0; }

@charset "UTF-8";
.BrowserWindow .addressbar {
  background-color: #EEE;
  display: flex;
  align-items: stretch;
  padding: .2rem .4rem; }
  .BrowserWindow .addressbar .url {
    flex: 1 1;
    border: solid 1px #CCC;
    border-radius: 4px 0 0 4px;
    padding: .2rem .4rem;
    background-color: white;
    color: #444;
    font-size: 0.8rem;
    -webkit-user-select: all;
       -moz-user-select: all;
        -ms-user-select: all;
            user-select: all;
    white-space: nowrap; }
  .BrowserWindow .addressbar .button {
    border: solid 1px #CCC;
    border-radius: 4px;
    padding: .2rem .4rem;
    color: #444;
    font-size: 0.8rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: pointer; }
    .BrowserWindow .addressbar .button:active {
      box-shadow: inset 0 0 4px #CCC; }
    .BrowserWindow .addressbar .button.button-refresh {
      margin-right: .2rem; }
      .BrowserWindow .addressbar .button.button-refresh:before {
        content: '↻'; }
    .BrowserWindow .addressbar .button.button-new {
      border-left: 0;
      border-radius: 0 4px 4px 0; }
      .BrowserWindow .addressbar .button.button-new:before {
        content: 'Open in a new tab'; }

.BrowserWindow .iframe {
  width: 100%;
  flex: 1 0;
  border: none; }

@charset "UTF-8";
.FinderWindow .panel {
  border-right: solid 1px #CCC;
  box-sizing: border-box;
  overflow-y: auto;
  display: flex;
  flex-direction: column; }
  .FinderWindow .panel.panel-preview {
    min-width: 24rem;
    flex: 1 1;
    border-right: none; }
    .FinderWindow .panel.panel-preview .preview {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 2rem; }
      .FinderWindow .panel.panel-preview .preview .image {
        max-height: 12rem;
        max-width: 16rem;
        margin-bottom: 1rem; }
      .FinderWindow .panel.panel-preview .preview .property-container {
        display: flex;
        flex-direction: column; }
        .FinderWindow .panel.panel-preview .preview .property-container .property {
          display: flex;
          margin-left: -1rem;
          margin-bottom: .8rem;
          color: #444;
          line-height: 1.5em; }
          .FinderWindow .panel.panel-preview .preview .property-container .property .key {
            font-size: 0.8rem;
            min-width: 4rem;
            color: #999;
            margin-right: .5rem;
            text-align: right; }
          .FinderWindow .panel.panel-preview .preview .property-container .property .value {
            flex-shrink: 1; }
            .FinderWindow .panel.panel-preview .preview .property-container .property .value p {
              margin: 0; }
            .FinderWindow .panel.panel-preview .preview .property-container .property .value a {
              color: #398FFB; }
  .FinderWindow .panel.panel-list {
    width: 12rem; }
    .FinderWindow .panel.panel-list .list {
      display: flex;
      flex-direction: column; }
      .FinderWindow .panel.panel-list .list .dir {
        display: flex;
        align-items: center;
        padding: .2rem .4rem; }
        .FinderWindow .panel.panel-list .list .dir .icon {
          height: 1.2rem;
          width: 1.2rem;
          -webkit-filter: drop-shadow(0 0 0.0375rem rgba(0, 0, 0, 0.5));
                  filter: drop-shadow(0 0 0.0375rem rgba(0, 0, 0, 0.5));
          margin-right: .4rem; }
        .FinderWindow .panel.panel-list .list .dir .name {
          flex-shrink: 1;
          font-size: 0.8rem;
          color: black; }
        .FinderWindow .panel.panel-list .list .dir.active {
          background-color: #116CD6; }
          .FinderWindow .panel.panel-list .list .dir.active .name {
            color: white; }

.mobile .FinderWindow .panel {
  border-right: none; }
  .mobile .FinderWindow .panel.panel-preview {
    min-width: auto; }
    .mobile .FinderWindow .panel.panel-preview .close {
      position: absolute;
      top: 0;
      right: 0;
      width: 2rem;
      height: 2rem;
      font-size: 1.2rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
      .mobile .FinderWindow .panel.panel-preview .close:after {
        content: '×'; }
  .mobile .FinderWindow .panel.panel-list {
    width: auto;
    flex: 1 1; }

.InstagramWindow .photo-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-shrink: 1;
  overflow-y: auto;
  margin-bottom: -3px; }
  .InstagramWindow .photo-container .photo {
    display: block;
    flex-basis: calc(33.33% - 2px);
    height: auto;
    background-size: cover;
    background-position: center;
    margin-bottom: 3px; }
    .InstagramWindow .photo-container .photo .ratio {
      width: 100%;
      height: auto; }

.InstagramWindow .embed-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.5);
  visibility: hidden;
  opacity: 0;
  -webkit-transition: visibility 0.2s, opacity 0.2s;
  transition: visibility 0.2s, opacity 0.2s; }
  .InstagramWindow .embed-container.opened {
    visibility: inherit;
    opacity: 1; }
  .InstagramWindow .embed-container iframe {
    width: calc(100% - 4rem) !important;
    margin: 2rem !important;
    box-shadow: 0 0 48px rgba(0, 0, 0, 0.3) !important; }

.PaypalWindow .paypal-success ~ .message:before {
  content: 'Thank you for your donation!'; }

.PaypalWindow .paypal-success ~ .donation-container {
  display: none; }

.PaypalWindow .message {
  color: white;
  font-size: 1.2rem;
  font-weight: lighter;
  padding: 2rem;
  text-align: center; }
  .PaypalWindow .message:before {
    content: 'parkjs814@gmail.com'; }

.PaypalWindow .donation-container {
  flex-shrink: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  margin: 0 2rem;
  padding-bottom: 2rem;
  overflow-y: auto;
  pointer-events: auto;
  -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, color-stop(0.9, black), to(rgba(0, 0, 0, 0))); }
  .PaypalWindow .donation-container .donation {
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    padding: 1rem;
    border-bottom: solid 1px rgba(255, 255, 255, 0.2); }
    .PaypalWindow .donation-container .donation:last-child {
      border-bottom: none; }
    .PaypalWindow .donation-container .donation .icon {
      height: 2rem;
      width: 2rem;
      -webkit-filter: drop-shadow(0 0 0.0625rem rgba(0, 0, 0, 0.5));
              filter: drop-shadow(0 0 0.0625rem rgba(0, 0, 0, 0.5));
      margin-right: .5rem; }
    .PaypalWindow .donation-container .donation .name {
      flex: 1 1;
      color: white;
      font-weight: lighter;
      margin-right: 2rem; }
    .PaypalWindow .donation-container .donation .price {
      color: white;
      font-weight: lighter;
      margin-right: 2rem;
      white-space: nowrap; }
      .PaypalWindow .donation-container .donation .price:before {
        content: '$\00a0'; }
    .PaypalWindow .donation-container .donation .donate {
      height: 3rem;
      border: none;
      border-radius: 1.5rem;
      background-color: #EBEBEB;
      color: #009cde;
      font-size: 1rem;
      display: flex;
      align-items: center;
      font-weight: bold;
      padding: 0 2rem;
      cursor: pointer; }
      .PaypalWindow .donation-container .donation .donate:hover {
        background-color: #DDD; }
      .PaypalWindow .donation-container .donation .donate:active {
        box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
        background-color: #d1d1d1;
        color: white; }
      .PaypalWindow .donation-container .donation .donate:before {
        content: 'Donate'; }

.mobile .PaypalWindow .donation-container .donation {
  flex-direction: column;
  text-align: center; }
  .mobile .PaypalWindow .donation-container .donation .icon {
    margin-right: 0;
    margin-bottom: .5rem; }
  .mobile .PaypalWindow .donation-container .donation .name {
    margin-right: 0;
    margin-bottom: 1.5rem; }
  .mobile .PaypalWindow .donation-container .donation .price {
    margin-right: 0;
    margin-bottom: .5rem; }

.TerminalWindow.focused .line-container .cursor {
  border: none;
  margin: 0;
  background-color: #2FCB29;
  color: black;
  -webkit-animation: blink 1s 1s infinite step-end;
          animation: blink 1s 1s infinite step-end; }

@-webkit-keyframes blink {
  from {
    background-color: #2FCB29;
    color: black; }
  50% {
    background-color: transparent;
    color: #2FCB29; } }

@keyframes blink {
  from {
    background-color: #2FCB29;
    color: black; }
  50% {
    background-color: transparent;
    color: #2FCB29; } }

.TerminalWindow .line-container {
  flex: 1 1;
  background-color: rgba(0, 0, 0, 0.9);
  padding: .2rem;
  overflow-y: auto;
  font-family: 'Roboto Mono', sans-serif;
  color: #2FCB29;
  font-weight: lighter;
  white-space: pre-wrap;
  word-break: break-all; }
  .TerminalWindow .line-container .cursor {
    border: solid 1px #2FCB29;
    margin: -1px;
    box-sizing: border-box; }
    .TerminalWindow .line-container .cursor:empty:before {
      content: '\00a0'; }
  .TerminalWindow .line-container .word-break {
    word-break: break-word; }
  .TerminalWindow .line-container .highlight {
    font-weight: bold; }
  .TerminalWindow .line-container .underline {
    text-decoration: underline; }
  .TerminalWindow .line-container .dir {
    color: #65A6CA;
    font-weight: bold; }
  .TerminalWindow .line-container .file {
    color: #9DD55E; }

.TerminalWindow .mobile-input {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0; }

.VersionHistoryWindow .version-container {
  flex: 1 1;
  display: flex;
  flex-direction: column; }
  .VersionHistoryWindow .version-container .version {
    flex: 1 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative; }
    .VersionHistoryWindow .version-container .version:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.2);
      opacity: 0;
      -webkit-transition: opacity 0.2s;
      transition: opacity 0.2s; }
    .VersionHistoryWindow .version-container .version:hover:after {
      opacity: 1; }
    .VersionHistoryWindow .version-container .version.version-0 {
      background-color: #80CBC4;
      color: #004D40; }
      .VersionHistoryWindow .version-container .version.version-0:before {
        content: 'Version 0'; }
    .VersionHistoryWindow .version-container .version.version-1 {
      background-color: white;
      color: black; }
      .VersionHistoryWindow .version-container .version.version-1:before {
        content: 'Version 1'; }
    .VersionHistoryWindow .version-container .version.version-2 {
      background-color: black;
      color: white; }
      .VersionHistoryWindow .version-container .version.version-2:before {
        content: 'Version 2'; }

.Desktop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #444;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 3rem; }
  .Desktop .wallpaper {
    background-position: center;
    background-size: cover;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: .4; }
  .Desktop .location {
    position: absolute;
    top: 0;
    left: 0;
    margin: 1rem;
    font-size: 0.8rem;
    color: white;
    display: flex;
    align-items: center;
    opacity: .4; }
    .Desktop .location .pinpoint {
      width: 1em;
      height: 1em;
      background-image: url(/static/media/pinpoint.12287d2c.png);
      background-size: contain;
      background-position: center;
      margin-right: .2rem; }
  .Desktop .forkme {
    position: absolute;
    top: 0;
    right: 2rem;
    -webkit-filter: drop-shadow(0 0 0.125rem rgba(0, 0, 0, 0.5));
            filter: drop-shadow(0 0 0.125rem rgba(0, 0, 0, 0.5)); }
  .Desktop .app-container {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    max-width: 32rem;
    justify-content: center;
    flex-shrink: 1;
    overflow-y: auto;
    padding: 1rem; }
    .Desktop .app-container .shortcut {
      -webkit-transition: background-color 0.2s;
      transition: background-color 0.2s;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      width: 8rem;
      height: 8rem;
      flex-direction: column;
      justify-content: center;
      padding: 1rem;
      border-radius: .2rem; }
      .Desktop .app-container .shortcut:hover {
        background-color: rgba(0, 0, 0, 0.2); }
      .Desktop .app-container .shortcut:active {
        background-color: rgba(0, 0, 0, 0.4); }
      .Desktop .app-container .shortcut.link-external .name {
        position: relative; }
        .Desktop .app-container .shortcut.link-external .name:after {
          position: absolute;
          content: '';
          display: inline-block;
          margin-left: .3rem;
          width: .8em;
          height: 100%;
          background: url(/static/media/link-external.cb80b6ab.png) no-repeat bottom;
          background-size: contain;
          opacity: .5; }
      .Desktop .app-container .shortcut .icon {
        height: 4rem;
        width: 4rem;
        -webkit-filter: drop-shadow(0 0 0.125rem rgba(0, 0, 0, 0.5));
                filter: drop-shadow(0 0 0.125rem rgba(0, 0, 0, 0.5));
        margin-bottom: .2rem; }
      .Desktop .app-container .shortcut .name {
        font-size: 0.8rem;
        color: white;
        text-shadow: 0 0 .4rem black;
        text-align: center;
        height: 1em; }
      .Desktop .app-container .shortcut:hover {
        box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.2); }
      .Desktop .app-container .shortcut:active {
        box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.4); }
  .Desktop .window-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none; }

.Icon {
  position: relative;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat; }
  .Icon .badge {
    position: absolute;
    top: 30%;
    left: 25%;
    right: 0;
    width: 50%;
    height: 50%;
    opacity: .5;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat; }

.Resume {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  box-sizing: border-box;
  background-color: #E9E9E9;
  font-family: 'Roboto', sans-serif;
  line-height: 1.15;
  padding: 0.336in;
  width: 100%;
  min-height: 100vh;
  -webkit-user-select: text;
     -moz-user-select: text;
      -ms-user-select: text;
          user-select: text;
  -webkit-font-smoothing: antialiased; }
  .Resume .paragraph {
    line-height: normal; }
  .Resume .paper {
    width: 8.5in;
    height: 11.0in;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    box-shadow: 0 0 0.336in rgba(0, 0, 0, 0.16);
    color: #404040;
    overflow: hidden; }
    .Resume .paper .header {
      flex: 1 1;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      background-color: #433952;
      color: #F8F8F8; }
      .Resume .paper .header .name {
        flex: 1 1;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-size: 0.28in;
        color: white; }
      .Resume .paper .header .bar {
        align-self: stretch;
        display: flex;
        background-color: rgba(255, 255, 255, 0.16); }
        .Resume .paper .header .bar .item {
          flex: 1 1;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0.084in;
          font-size: 0.126in;
          color: #F8F8F8; }
          .Resume .paper .header .bar .item svg {
            margin-right: 0.028in; }
    .Resume .paper .body {
      flex: 1 1;
      display: flex;
      flex-direction: row-reverse;
      align-items: stretch; }
    .Resume .paper .section {
      font-size: 0.154in;
      display: flex;
      flex-direction: column; }
      .Resume .paper .section .title {
        display: flex;
        align-items: center;
        font-weight: bold;
        margin: 0.056in 0; }
        .Resume .paper .section .title:after {
          content: '';
          margin-left: 0.224in;
          flex: 1 1;
          height: 2px;
          background-color: #F8F8F8; }
      .Resume .paper .section .content.wrap {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between; }
        .Resume .paper .section .content.wrap .item {
          width: 48%; }
          .Resume .paper .section .content.wrap .item .row .name {
            flex: 1 1; }
          .Resume .paper .section .content.wrap .item .row .date {
            flex: 0 1; }
      .Resume .paper .section .content .item {
        display: flex;
        flex-direction: column;
        margin-bottom: 0.056in; }
        .Resume .paper .section .content .item .row,
        .Resume .paper .section .content .item .bullet {
          margin-bottom: 0.056in; }
        .Resume .paper .section .content .item .row {
          display: flex; }
          .Resume .paper .section .content .item .row.indented {
            margin-left: 0.168in; }
          .Resume .paper .section .content .item .row .spacer {
            flex: 1 1; }
          .Resume .paper .section .content .item .row > *:after {
            content: ' ';
            white-space: pre; }
          .Resume .paper .section .content .item .row > *:last-child {
            margin-right: 0; }
          .Resume .paper .section .content .item .row .secondary {
            color: #808080; }
        .Resume .paper .section .content .item .bullet {
          color: #808080;
          margin-left: 0.168in; }
          .Resume .paper .section .content .item .bullet:before {
            position: absolute;
            content: '-';
            margin-left: -0.112in; }
    .Resume .paper .narrow {
      background-color: #F8F8F8;
      width: 2.24in; }
    .Resume .paper .wide {
      background-color: #FFFFFF;
      flex: 1 1; }
    .Resume .paper .narrow,
    .Resume .paper .wide {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 0.168in 0.252in; }

@media print {
  @page {
    margin: 0; }
  .Resume {
    padding: 0;
    min-height: 100%; }
    .Resume .paper {
      box-shadow: none; } }

.Menu {
  position: absolute;
  z-index: 99999;
  top: 0;
  bottom: 3rem;
  left: 0;
  right: 0;
  pointer-events: none;
  overflow: hidden;
  -webkit-animation: 0.2s opening;
          animation: 0.2s opening; }

@-webkit-keyframes opening {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes opening {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
  .Menu.closing {
    -webkit-animation: 0.2s forwards closing;
            animation: 0.2s forwards closing; }

@-webkit-keyframes closing {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes closing {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }
  .Menu .overlay {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 24rem;
    height: 60vh;
    background-color: #444;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    box-shadow: 0 0 48px rgba(0, 0, 0, 0.4);
    pointer-events: auto;
    border-radius: 5px;
    margin: 1rem;
    overflow: hidden; }
    .Menu .overlay .list {
      flex: 1 1;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      overflow-y: auto; }
      .Menu .overlay .list .group {
        display: flex;
        flex-direction: column;
        align-items: stretch; }
        .Menu .overlay .list .group .header {
          color: white;
          padding: .4rem 1.2rem;
          margin-top: 1.2rem;
          font-size: 0.8rem; }
        .Menu .overlay .list .group .item {
          -webkit-transition: background-color 0.2s;
          transition: background-color 0.2s;
          display: flex;
          align-items: center;
          padding: .4rem 1.2rem; }
          .Menu .overlay .list .group .item:hover {
            background-color: rgba(0, 0, 0, 0.2); }
          .Menu .overlay .list .group .item:active {
            background-color: rgba(0, 0, 0, 0.4); }
          .Menu .overlay .list .group .item.indented {
            padding-left: 3.8rem; }
          .Menu .overlay .list .group .item .icon {
            height: 2.2rem;
            width: 2.2rem;
            -webkit-filter: drop-shadow(0 0 0.06875rem rgba(0, 0, 0, 0.5));
                    filter: drop-shadow(0 0 0.06875rem rgba(0, 0, 0, 0.5));
            margin-right: .4rem; }
          .Menu .overlay .list .group .item .name {
            flex: 1 1;
            font-size: 0.8rem;
            color: #DDD; }
          .Menu .overlay .list .group .item .total {
            font-size: 0.8rem;
            color: rgba(221, 221, 221, 0.2); }
    .Menu .overlay .search-container {
      border-top: 1px solid rgba(221, 221, 221, 0.1);
      display: flex;
      height: 4rem;
      align-items: stretch; }
      .Menu .overlay .search-container .search {
        flex: 1 1;
        border: none;
        font-family: inherit;
        font-size: inherit;
        color: white;
        background: none;
        padding: 0 1.2rem; }

.mobile .Menu .overlay {
  width: auto;
  height: auto;
  top: 0;
  right: 0; }

.Screen {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.Taskbar {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99999;
  height: 3rem;
  display: flex;
  align-items: stretch;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.2); }
  .Taskbar .label {
    display: flex;
    align-items: center;
    padding: 0 1rem;
    white-space: nowrap; }
    .Taskbar .label.label-profile {
      -webkit-transition: background-color 0.2s;
      transition: background-color 0.2s; }
      .Taskbar .label.label-profile:hover {
        background-color: rgba(0, 0, 0, 0.2); }
      .Taskbar .label.label-profile:active {
        background-color: rgba(0, 0, 0, 0.4); }
      .Taskbar .label.label-profile .avatar {
        height: 2rem;
        width: 2rem;
        margin-right: .5rem;
        border-radius: .2rem;
        background-size: cover;
        background-position: center; }
    .Taskbar .label .name {
      font-size: 0.8rem;
      color: white; }
  .Taskbar .shortcut-container {
    flex: 1 1;
    display: flex;
    overflow-x: auto; }
    .Taskbar .shortcut-container .shortcut {
      -webkit-transition: background-color 0.2s;
      transition: background-color 0.2s;
      position: relative;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      justify-content: flex-start;
      overflow: hidden;
      max-width: 0;
      opacity: 0;
      -webkit-transition: max-width 0.2s, opacity 0.2s, background-color 0.2s;
      transition: max-width 0.2s, opacity 0.2s, background-color 0.2s; }
      .Taskbar .shortcut-container .shortcut:hover {
        background-color: rgba(0, 0, 0, 0.2); }
      .Taskbar .shortcut-container .shortcut:active {
        background-color: rgba(0, 0, 0, 0.4); }
      .Taskbar .shortcut-container .shortcut .icon {
        height: 2.2rem;
        width: 2.2rem;
        -webkit-filter: drop-shadow(0 0 0.06875rem rgba(0, 0, 0, 0.5));
                filter: drop-shadow(0 0 0.06875rem rgba(0, 0, 0, 0.5));
        margin: 0 1rem; }
      .Taskbar .shortcut-container .shortcut:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        border-bottom: solid 3px white;
        opacity: 0;
        -webkit-transition: opacity 0.2s;
        transition: opacity 0.2s; }
      .Taskbar .shortcut-container .shortcut.active {
        max-width: 4.2rem;
        opacity: 1; }
        .Taskbar .shortcut-container .shortcut.active:after {
          opacity: 1; }
      .Taskbar .shortcut-container .shortcut.pinned {
        max-width: 4.2rem;
        opacity: 1; }

@charset "UTF-8";
.Window {
  position: absolute;
  background-color: white;
  box-shadow: 0 0 48px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
  pointer-events: auto;
  -webkit-transition: top 0.2s, left 0.2s, width 0.2s, height 0.2s, box-shadow 0.2s, -webkit-transform 0.2s;
  transition: top 0.2s, left 0.2s, width 0.2s, height 0.2s, box-shadow 0.2s, -webkit-transform 0.2s;
  transition: top 0.2s, left 0.2s, width 0.2s, height 0.2s, box-shadow 0.2s, transform 0.2s;
  transition: top 0.2s, left 0.2s, width 0.2s, height 0.2s, box-shadow 0.2s, transform 0.2s, -webkit-transform 0.2s;
  -webkit-animation: 0.2s opening;
          animation: 0.2s opening; }

@-webkit-keyframes opening {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes opening {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
  .Window.closing {
    -webkit-animation: 0.2s forwards closing;
            animation: 0.2s forwards closing; }

@-webkit-keyframes closing {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes closing {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }
  .Window.focused {
    box-shadow: 0 0 48px rgba(0, 0, 0, 0.4); }
    .Window.focused .toolbar .button-container .button.button-close {
      background-color: #FC615D; }
    .Window.focused .toolbar .button-container .button.button-minimize {
      background-color: #FDBC40; }
    .Window.focused .toolbar .button-container .button.button-maximize {
      background-color: #34C749; }
    .Window.focused .content .interceptor {
      display: none; }
  .Window.moving, .Window.resizing {
    -webkit-transition: none;
    transition: none; }
    .Window.moving .content .interceptor, .Window.resizing .content .interceptor {
      display: block; }
  .Window.no-toolbar .toolbar {
    flex: 1 1;
    border-bottom: none;
    background: none; }
    .Window.no-toolbar .toolbar .title-container .name {
      color: #DDD; }
  .Window.no-toolbar .content {
    position: absolute;
    top: 2.8rem;
    bottom: 0;
    width: 100%;
    pointer-events: none;
    box-sizing: border-box; }
  .Window.maximized {
    top: 0 !important;
    left: 0 !important;
    height: calc(100% - 3rem) !important;
    width: 100vw !important; }
  .Window.minimized {
    top: 100vh !important;
    -webkit-transform: scale(0);
            transform: scale(0); }
  .Window .toolbar {
    background-color: #DDD;
    display: flex;
    flex-direction: column;
    border-bottom: solid 1px #CCC;
    overflow: hidden; }
    .Window .toolbar .button-container {
      position: absolute;
      padding: 1rem .8rem;
      display: flex; }
      .Window .toolbar .button-container .button {
        background-color: #CCC;
        width: .8rem;
        height: .8rem;
        border: solid 1px rgba(0, 0, 0, 0.1);
        border-radius: .8rem;
        box-sizing: border-box;
        margin-right: .5rem;
        -webkit-transition: background-color 0.2s;
        transition: background-color 0.2s; }
        .Window .toolbar .button-container .button:last-child {
          margin-right: 0; }
        .Window .toolbar .button-container .button:active {
          border-width: 2px; }
    .Window .toolbar .title-container {
      align-self: stretch;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: .8rem 5rem; }
      .Window .toolbar .title-container .icon {
        height: 1.2rem;
        width: 1.2rem;
        -webkit-filter: drop-shadow(0 0 0.0375rem rgba(0, 0, 0, 0.5));
                filter: drop-shadow(0 0 0.0375rem rgba(0, 0, 0, 0.5));
        margin-right: .4rem; }
      .Window .toolbar .title-container .name {
        font-size: 0.8rem;
        color: #444;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        min-width: 0; }
    .Window .toolbar .tab-container {
      align-self: stretch;
      margin-left: 5.8rem;
      display: flex;
      align-items: flex-end;
      height: 2.8rem; }
      .Window .toolbar .tab-container .tab {
        position: relative;
        flex: 1 1;
        display: flex;
        align-items: center;
        height: 2rem;
        background-color: #DDD;
        margin-right: 1rem;
        max-width: 12rem;
        padding: 0 .5rem;
        -webkit-transition: background-color 0.2s;
        transition: background-color 0.2s;
        cursor: pointer; }
        .Window .toolbar .tab-container .tab .icon {
          height: 1.2rem;
          width: 1.2rem;
          -webkit-filter: drop-shadow(0 0 0.0375rem rgba(0, 0, 0, 0.5));
                  filter: drop-shadow(0 0 0.0375rem rgba(0, 0, 0, 0.5));
          margin-right: .4rem; }
        .Window .toolbar .tab-container .tab .name {
          flex: 1 1;
          font-size: 0.8rem;
          color: #444;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          width: 0;
          margin-right: .4rem; }
        .Window .toolbar .tab-container .tab .close {
          position: relative;
          color: #888;
          display: flex;
          align-items: center;
          justify-content: center;
          -webkit-transition: color 0.2s;
          transition: color 0.2s;
          cursor: pointer; }
          .Window .toolbar .tab-container .tab .close:after {
            content: '×'; }
          .Window .toolbar .tab-container .tab .close:before {
            content: '';
            position: absolute;
            z-index: -1;
            width: .8rem;
            height: .8rem;
            border-radius: .8rem;
            box-sizing: border-box;
            -webkit-transition: background-color 0.2s;
            transition: background-color 0.2s; }
          .Window .toolbar .tab-container .tab .close:hover {
            color: #CCC; }
            .Window .toolbar .tab-container .tab .close:hover:before {
              background-color: #FC615D; }
        .Window .toolbar .tab-container .tab:before, .Window .toolbar .tab-container .tab:after {
          position: absolute;
          content: '';
          top: 0;
          width: 0;
          height: 0;
          border-bottom: 2rem solid #DDD;
          -webkit-transition: border-color 0.2s;
          transition: border-color 0.2s; }
        .Window .toolbar .tab-container .tab:before {
          left: -1rem;
          border-left: 1rem solid transparent; }
        .Window .toolbar .tab-container .tab:after {
          right: -1rem;
          border-right: 1rem solid transparent; }
        .Window .toolbar .tab-container .tab.active {
          z-index: 1;
          background-color: #CCC; }
          .Window .toolbar .tab-container .tab.active:before, .Window .toolbar .tab-container .tab.active:after {
            border-bottom-color: #CCC; }
  .Window .content {
    position: relative;
    flex: 1 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: stretch; }
    .Window .content .interceptor {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0; }
  .Window .border {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0; }
    .Window .border.border-top, .Window .border.border-bottom {
      height: 4px;
      cursor: ns-resize; }
    .Window .border.border-top {
      bottom: auto; }
    .Window .border.border-bottom {
      top: auto; }
    .Window .border.border-left, .Window .border.border-right {
      width: 4px;
      cursor: ew-resize; }
    .Window .border.border-left {
      right: auto; }
    .Window .border.border-right {
      left: auto; }
    .Window .border.border-top.border-left, .Window .border.border-bottom.border-right {
      cursor: nwse-resize; }
    .Window .border.border-top.border-right, .Window .border.border-bottom.border-left {
      cursor: nesw-resize; }

.mobile .Window {
  top: 0 !important;
  left: 0 !important;
  height: calc(100% - 3rem) !important;
  width: 100vw !important; }

