$font-size-large: 1.2rem;
$font-size-normal: 1rem;
$font-size-small: .8rem;
$taskbar-height: 3rem;
$anim-duration: .2s;
$toolbar-height: 2.8rem;
$color-dark: #444;
$color-light: #DDD;
$theme-color: $color-dark;

@mixin icon($size) {
  height: $size;
  width: $size;
  filter: drop-shadow(0 0 $size / 32 rgba(black, .5));
}

@mixin hoverable() {
  transition: background-color $anim-duration;

  &:hover {
    background-color: rgba(black, .2);
  }

  &:active {
    background-color: rgba(black, .4);
  }
}